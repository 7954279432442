//
// Footer

.c-global-footer {
    background-color: $black;
    color: $white;
    padding: $spacer-m 0;

    @include mq($from: tablet) {
        padding: $spacer-l 0;
    }
}

// Sections

.c-global-footer {
    &__top-row {
        position: relative;
    }

    &__content {
        border-top: 5px solid $white;
        border-bottom: 2px solid $white;
        padding: $spacer-s 0;

        @include mq($from: $tablet) {
            padding: $spacer-l 0;
        }
    }

    &__bottom-row {
        position: relative;
        padding: $spacer-s 0;

        &-rhs {
            @include mq($from: tablet) {
                position: absolute;
                right: 0;
                top: $spacer-s;
            }
        }
    }
}

// Elements

.c-global-footer {
    &__logo {
        width: 150px;
        display: inline-block;
        margin-bottom: $spacer-s;

        * {
            fill: $white !important;
        }
    }

    &__wysiwyg {
        margin-bottom: $spacer-l;

        @include mq($from: tablet) {
            margin: 0;
        }

        .smaller-p {

            & + .lead-p {
                margin-top: 0;
            }

        }

        .lead-p {
            @include font-size-1;
            @include font-bold;
            a {
                @include font-size-1;
                @include font-bold;
                color: $white;
            }
        }
    }

    &__copyright {
        @include font-size-minus-1;
    }
}

.c-social-links {
    position: absolute;
    right: 0;
    bottom: $spacer-s;
    @include font-size-1;
    display: none;

    @include mq($from: 400px) {
        display: block;
    }

    &__item {
        margin-right: $spacer-m;

        &:last-of-type {
            margin: 0;
        }
    }
}

.c-support-logos {
    display: inline-block;
    margin: 0;
    margin-top: $spacer-m;
    padding: 0;

    @include mq($from: tablet) {
        margin-top: 0;
    }

    &__item {
        margin-right: $spacer-m;
        display: inline-block;

        @include mq($from: tablet) {
            margin-right: 0;
            margin-left: $spacer-m;
        }
    }

    &__logo {
        height: 40px;
        width: auto;
    }
}

// Menus

.c-footer-menu {
    margin: 0;
    padding: 0;

    @include mq($from: tablet) {
        column-count: 2;
    }

    @include mq($from: desktop) {
        column-count: 3;
    }

    li {
        margin: 0;
        margin-bottom: $spacer-m;
        padding: 0;
    }

    a {
        text-transform: none;
        @include font-semi-bold;
        @include font-size-1;
        color: inherit;
        &:hover, &:focus, &:active {
            text-decoration: none;
            color: inherit;
        }
    }
}


.c-footer-menu-secondary {
    margin: 0;
    margin-top: $spacer-xs;
    padding: 0;

    li {
        display: inline-block;
        margin-right: $spacer-s;
    }

    a {
        text-transform: none;
        color: inherit;
        @include font-size-minus-1;
        @include font-reg;
        &:hover, &:focus, &:active {
            text-decoration: none;
            color: inherit;
        }
    }
}

.o-list-bare {
    list-style: none;
    margin-left: 0;
}