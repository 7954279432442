.tn-prod-season-header .tn-prod-season-header__title, .tn-heading {
	@include font-size-3;
	@include heading-caps;
	color: $black;
	a {
		color: inherit;
	}
}

.tn-prod-season-header__date-container {
	display: none;
}

.tn-event-detail__performance-details-container {
	.tn-event-detail__display-time {
		@include font-size-1;
		@include heading-regular;
	}

	.tn-event-detail__location {
		@include font-size-1;
		@include heading-regular;
	}

	.tn-event-detail__description {
		@include font-size-0;
	}
}

.tn-event-detail__additional-events-container {
	.tn-additional-events__instructions {
		@include font-size-0;
		@include heading-regular;
	}
	button.tn-additional-events__btn-submit {
		font-size: 1rem;
		padding: 6px 12px;
	}
}