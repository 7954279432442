.tn-syos-screen-container {
	.tn-syos-screen-button:not([disabled]) {
		@include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
	}
	.tn-syos-screen-button[disabled] {
		@include button-variant($gray-light, $gray-lighter, $gray-light);

	}
}
.tn-syos-screen-container__button-list-item {
	.tn-syos-screen-button:not([disabled]) {
		@include button-variant($black, $white, $gray-light);
		&:hover,
		&:focus,
		&.focus {
    		background-color: darken($white, 5%);
		}
	}
}

button.tn-syos__btn-add-to-cart {
    white-space: pre-wrap;
    background-color: $color-red;
    color: $black;
    text-transform: capitalize;
    @include font-bold;
}