.tn-account-login-page {
	.tn-register-button {
		@include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
	}
	.tn-account-login-forgot-login-link {
		@include c-cta;
		text-decoration: none;
		color: inherit;
		margin-bottom: 1em;
	}
}


.tn-account-memberships-page {
	.tn-empty-cart-message {
		color: $black;
		text-align: left;
	}
}

.tn-account-update-page {
	#tn-update-button {
		margin-top: 1em;
	}
}

.tn-account-interests-page {
	.btn-primary {
		margin-top: 1em;
	}	
}

// Customisations for the account/create/brief page
.tn-account-create-brief-page {
	// Remove all unwanted headings
	.row h2 {
	  display: none;
	}
	// The 'Also keep in touch by...' box for post/phone/txt permissions
	.tn-component-contact-permissions__settings__item:nth-child(2) {
	  display: none;
	}
	// The contact preferences for email lists
	.tn-component-contact-permissions__settings__item {
	  border: none;
	  padding: 0;
	  // Contact preferences subtitle 'Email me about...'
	  .tn-component-contact-permissions__settings__item__header {
		display: none;
	  }
	  // The 'Supporting the Almeida' preference
	  & > .ng-scope:nth-child(3) {
		display: none;
	  }
	  // The Yes/No radio buttons for each preference
	  .tn-component__radio-group > div {
		display: inline-block;
		padding-right: 1rem;
	  }
	}
  }
  