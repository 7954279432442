.tn-btn-datepicker__icon-container {
	.glyphicon {
		color: $white;
	}
}

// Calender view
.tn-events-calendar-view {
	.tn-event-listing-view__results-container {
		// Calendar Title Color
		.tn-event-listing-view__results-heading {
			color: $black;
		}
		// Calendar Day Number
		.tn-events-calendar__day-number {
			color: $black;
		}
		// Calendar Heading
		.tn-events-calendar__table th, .tn-events-calendar__table thead {
			background-color: $gray-dark;
		}
	}
}

// List view
.tn-events-list-view {
	// Hide the date
	.tn-event-listing-view__results-heading {
		display: none;
	}
	// Hide the filters
	.tn-event-listing-view__controls-container {
		display: none;
	}
}

// Tab color
.tn-events-listing-page {
	.tn-event-listing-mode-tab-nav__list-item {
		color: $black;
	}
}

.tn-prod-list-item__perf-anchor .btn {
	padding: initial;
}

//
// Grid Layout for List Items

//If the view has more than 1 item, then it's the default list view
.tn-event-listing-view__results-container:has(.tn-prod-list-item:nth-of-type(2)) {
	// So turn the events list into a grid layout
	> ul {
		display: grid;
		grid-template-columns: 1fr;
		gap: $spacer-m;
		@include mq($from: tablet) {
			grid-template-columns: 1fr 1fr;
			gap: $spacer-m $spacer-l;
		}
		@include mq($from: desktop) {
			grid-template-columns: 1fr 1fr 1fr;
			gap: $spacer-m $spacer-l;
		}
	}
	.tn-prod-list-item {
		padding: 0;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		position: relative;
		background-color: transparent;
		border: none;
		&:hover, &:focus {
			.tn-prod-list-item__property--heading {
				bottom: $spacer-m + 3px;
			}
		}
		.tn-prod-list-item__property--perf-list {
			display: none;
		}
		.tn-prod-list-item__property--heading {
			grid-column: 1;
			padding: $spacer-s $spacer-m;
			position: absolute;
			background-color: $black;
			color: $white;
			bottom: $spacer-m;
			left: -$spacer-s;
			z-index: 100;
			max-width: 85%;
		    pointer-events: none;
		    transition: $global-transition;
			a {
				color: $white;
			}
		}
	}
	.tn-prod-list-item__property--img-container {
		display: block;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		width: 100%;
		padding-top: 62.5%;
		overflow: hidden;
		a {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			right: 0;
		    bottom: 0;
			z-index: 0;
			width: 100%;
			overflow: hidden;
			background-color: $gray-lighter;
		}
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
}
