
.tn-plug-in-add-ons {
	.tn-plug-in-add-ons-group-header {
		display: none;
	}

	.tn-plug-in-add-ons-item-heading {
		margin-top: 0;
	}

	@media (min-width: 768px) {
		.tn-plug-in-add-ons-item {
		    grid-column-gap: 24px;
			.tn-plug-in-add-ons-item-image {
				grid-row: 1 / span 3;
			}
		}
	}
}