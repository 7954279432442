.tn-events-keyword-filter__list {
	display: none;
}

.tn-events-keyword-filter {
	display: none !important;
}

.tn-prod-list-item__property {

}
.tn-prod-list-item {
	.tn-prod-list-item__property--heading {
		@include font-size-2;
		a {
			color: $black;
		}
	}
}

@media (min-width: 768px) {
	.tn-prod-list-item {
		.tn-prod-list-item__property--img-container {
		    display: none;
		}
		.tn-prod-list-item__property--heading {
		    grid-column: 1 / span 2;
		}
		.tn-prod-list-item__property--perf-list {
		    grid-column: 1 / span 2;
		}
	}
}