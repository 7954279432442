/* ==========================================================================
   #LAYOUT
   ========================================================================== */

// Baseline grid lines height.
// Every spacing metric should be based on this.

$inuit-global-baseline:     6px !default;



// How many grid lines should our spacing unit variants span?
// Each value should be an unitless integer.

$inuit-global-spacing-unit-factor-tiny:   1 !default;   // 6px
$inuit-global-spacing-unit-factor-small:  2 !default;   // 12px
$inuit-global-spacing-unit-factor:        4 !default;   // 24px
$inuit-global-spacing-unit-factor-large:  8 !default;   // 48px
$inuit-global-spacing-unit-factor-huge:   16 !default;  // 96px



// Spacing values are determined based on your project’s global baseline grid.
// It is not recommended that you modify these following variables
// (it can break your vertical rhythm), but if you need to, you can.

$inuit-global-spacing-unit:       $inuit-global-baseline * $inuit-global-spacing-unit-factor !default;
$inuit-global-spacing-unit-tiny:  $inuit-global-baseline * $inuit-global-spacing-unit-factor-tiny !default;
$inuit-global-spacing-unit-small: $inuit-global-baseline * $inuit-global-spacing-unit-factor-small !default;
$inuit-global-spacing-unit-large: $inuit-global-baseline * $inuit-global-spacing-unit-factor-large !default;
$inuit-global-spacing-unit-huge:  $inuit-global-baseline * $inuit-global-spacing-unit-factor-huge !default;

$inuit-layout-gutter:       $inuit-global-spacing-unit !default;
$inuit-layout-tiny-gutter:  $inuit-global-spacing-unit-tiny !default;
$inuit-layout-small-gutter: $inuit-global-spacing-unit-small !default;
$inuit-layout-large-gutter: $inuit-global-spacing-unit-large !default;
$inuit-layout-huge-gutter:  $inuit-global-spacing-unit-huge !default;

// Base typographical styles.

$inuit-global-font-size:    16px !default;
$inuit-global-line-height:  $inuit-global-spacing-unit !default;





// Check that the chosen font rules are pixel numbers.

@each $_inuit-font-globals in
      $inuit-global-font-size
      $inuit-global-line-height {

  @if (type-of($_inuit-font-globals) == number) {

    @if (unit($_inuit-font-globals) != "px") {
      @error "`#{$_inuit-font-globals}` needs to be a pixel value.";
    }

  } @else {
    @error "`#{$_inuit-font-globals}` needs to be a number.";
  }

}



// Check that the chosen size factors are unitless, integer numbers.

@each $_inuit-spacing-unit in
      $inuit-global-spacing-unit-factor-tiny
      $inuit-global-spacing-unit-factor-small
      $inuit-global-spacing-unit-factor-large
      $inuit-global-spacing-unit-factor-huge {

  @if (type-of($_inuit-spacing-unit) == number) {

    @if (unitless($_inuit-spacing-unit) == false) {
      @error "`#{$_inuit-spacing-unit}` needs to be unitless.";
    }

    @if ($_inuit-spacing-unit != ceil($_inuit-spacing-unit)) {
      @error "`#{$_inuit-spacing-unit}` needs to be an integer.";
    }

  } @else {
    @error "`#{$_inuit-spacing-unit}` needs to be a number.";
  }

}

/**
 * Grid-like layout system.
 *
 * The layout object provides us with a column-style layout system. This file
 * contains the basic structural elements, but classes should be complemented
 * with width utilities, for example:
 *
 *   <div class="o-layout">
 *     <div class="o-layout__item  u-1/2">
 *     </div>
 *     <div class="o-layout__item  u-1/2">
 *     </div>
 *   </div>
 *
 * The above will create a two-column structure in which each column will
 * fluidly fill half of the width of the parent. We can have more complex
 * systems:
 *
 *   <div class="o-layout">
 *     <div class="o-layout__item  u-1/1  u-1/3@medium">
 *     </div>
 *     <div class="o-layout__item  u-1/2  u-1/3@medium">
 *     </div>
 *     <div class="o-layout__item  u-1/2  u-1/3@medium">
 *     </div>
 *   </div>
 *
 * The above will create a system in which the first item will be 100% width
 * until we enter our medium breakpoint, when it will become 33.333% width. The
 * second and third items will be 50% of their parent, until they also become
 * 33.333% width at the medium breakpoint.
 *
 * We can also manipulate entire layout systems by adding a series of modifiers
 * to the `.o-layout` block. For example:
 *
 *   <div class="o-layout  o-layout--reverse">
 *
 * This will reverse the displayed order of the system so that it runs in the
 * opposite order to our source, effectively flipping the system over.
 *
 *   <div class="o-layout  o-layout--[right|center]">
 *
 * This will cause the system to fill up from either the centre or the right
 * hand side. Default behaviour is to fill up the layout system from the left.
 *
 * There are plenty more options available to us: explore them below.
 */

// By default we use the `font-size: 0;` trick to remove whitespace between
// items. Set this to true in order to use a markup-based strategy like
// commenting out whitespace or minifying HTML.
$inuit-use-markup-fix: false !default;





/* Default/mandatory classes
   ========================================================================== */

/**
 * 1. Allows us to use the layout object on any type of element.
 * 2. We need to defensively reset any box-model properties.
 * 3. Use the negative margin trick for multi-row grids:
 *    http://csswizardry.com/2011/08/building-better-grid-systems/
 */

.o-layout {
  display: block; /* [1] */
  margin:  0; /* [2] */
  padding: 0; /* [2] */
  list-style: none; /* [1] */
  margin-left: -$inuit-layout-gutter; /* [3] */

  @if ($inuit-use-markup-fix == false) {
    font-size: 0;
  }

}

  /**
   * 1. Required in order to combine fluid widths with fixed gutters.
   * 2. Allows us to manipulate grids vertically, with text-level properties,
   *    etc.
   * 3. Default item alignment is with the tops of each other, like most
   *    traditional grid/layout systems.
   * 4. By default, all layout items are full-width (mobile first).
   * 5. Gutters provided by left padding:
   *    http://csswizardry.com/2011/08/building-better-grid-systems/
   * 6. Fallback for old IEs not supporting `rem` values.
   */

  .o-layout__item {
    box-sizing: border-box; /* [1] */
    display: inline-block; /* [2] */
    vertical-align: top; /* [3] */
    width: 100%; /* [4] */
    padding-left: $inuit-layout-gutter; /* [5] */

    @if ($inuit-use-markup-fix == false) {
      font-size: $inuit-global-font-size; /* [6] */
      font-size: 1rem;
    }

  }





/* Gutter size modifiers
   ========================================================================== */

.o-layout--flush {
  margin-left: 0;

  > .o-layout__item {
    padding-left: 0;
  }

}


.o-layout--tiny {
  margin-left: -$inuit-layout-tiny-gutter;

  > .o-layout__item {
    padding-left: $inuit-layout-tiny-gutter;
  }

}


.o-layout--small {
  margin-left: -$inuit-layout-small-gutter;

  > .o-layout__item {
    padding-left: $inuit-layout-small-gutter;
  }

}


.o-layout--large {
  margin-left: -$inuit-layout-large-gutter;

  > .o-layout__item {
    padding-left: $inuit-layout-large-gutter;
  }

}


.o-layout--huge {
  margin-left: -$inuit-layout-huge-gutter;

  > .o-layout__item {
    padding-left: $inuit-layout-huge-gutter;
  }

}





/* Vertical alignment modifiers
   ========================================================================== */

/**
 * Align all grid items to the middles of each other.
 */

.o-layout--middle {

  > .o-layout__item {
    vertical-align: middle;
  }

}


/**
 * Align all grid items to the bottoms of each other.
 */

.o-layout--bottom {

  > .o-layout__item {
    vertical-align: bottom;
  }

}


/**
 * Stretch all grid items of each row to have an equal-height.
 * Please be aware that this modifier class doesn’t take any effect in IE9 and
 * below and other older browsers due to the lack of `display: flex` support.
 */

.o-layout--stretch {
  display: flex;
  flex-wrap: wrap;

  > .o-layout__item {
    display: flex;
  }

  &.o-layout--center {
    justify-content: center;
  }

  &.o-layout--right {
    justify-content: flex-end;
  }

  &.o-layout--left {
    justify-content: flex-start;
  }

}





/* Fill order modifiers
   ========================================================================== */

/**
 * Fill up the layout system from the centre.
 */

.o-layout--center {
  text-align: center;

  > .o-layout__item {
    text-align: left;
  }

}


/**
 * Fill up the layout system from the right-hand side.
 */

.o-layout--right {
  text-align: right;

  > .o-layout__item {
    text-align: left;
  }

}


/**
 * Fill up the layout system from the left-hand side. This will likely only be
 * needed when using in conjunction with `.o-layout--reverse`.
 */

.o-layout--left {
  text-align: left;

  > .o-layout__item {
    text-align: left;
  }

}


/**
 * Reverse the rendered order of the grid system.
 */

.o-layout--reverse {
  direction: rtl;

  > .o-layout__item {
    direction: ltr;
  }

}





/* Auto-widths modifier
   ========================================================================== */

/**
 * Cause layout items to take up a non-explicit amount of width.
 */

.o-layout--auto {

  > .o-layout__item {
    width: auto;
  }

}
