// WebKit-style focus
@mixin tab-focus() {
  // Default
  outline: thin dotted;
  // WebKit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

@mixin focus-outline {
    outline-offset: 4px;
    outline: 3px solid $color-red;
}