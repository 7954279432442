.page-wrap {
    background-color: darken($white, 5%);
}

.content-wrap {
    padding: $padding-base-vertical $padding-large-horizontal;
}

.page-wrap .content-wrap {
    background: $white;
    padding-bottom: 50px;
    min-height: 80vh;
}

.max-width {
    max-width: $max-site-width;
    margin: 0 auto;
}

/*  ==========================================================================
    #WRAPPER
    ========================================================================== */

.o-wrapper {
    padding-right: $spacer-s;
    padding-left:  $spacer-s;
    margin-right: auto;
    margin-left:  auto;
    max-width: $max-site-width;

    @include mq($from: tablet) {
      padding-right: $spacer-m;
      padding-left:  $spacer-m;
    }
}
