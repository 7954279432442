//
// Header

$header-height-mobile: 60px;
$header-height-desktop: 105px;
$header-full-display: 1140px;

@keyframes headerLines {
	from {
		transform: scaleX(0);
	}

	to {
		transform: scaleX(1);
	}
}

.page-wrap {
	padding-top: $header-height-mobile;

	@include mq($from: $header-full-display) {
		padding-top: $header-height-desktop;
	}
}

// Header styles

.c-global-head {
	border-top: 2px solid $white;
	border-bottom: 2px solid $white;
	background-color: $black;
	color: $white;
	height: $header-height-mobile;

	position: fixed;
	z-index: 302;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	.admin-bar & {
		top: 32px;
	}

	@include mq($from: $header-full-display) {
		height: $header-height-desktop;
		border: 0;
	}

	&__container {
		padding-top: $spacer-s;
		padding-bottom: $spacer-s;
		position: relative;
	}

	&__rhs, &__lhs {
		position: absolute;
		top: 12px;

		@include mq($from: $header-full-display) {
			padding: 23px 0;
			width: calc(50% - 170px);

			&::before, &::after {
				content: '';
				position: absolute;
				background-color: $white;
				width: 100%;
				animation: headerLines 1.5s ease-out forwards;
			}

			&::before {
				height: 5px;
				top: 0;
				left: 0;
			}

			&::after {
				height: 2px;
				bottom: 0;
				right: 0;
			}
		}
	}

	&__lhs {
		&::before, &::after {
			transform-origin: right;
		}
	}

	&__rhs {
		right: 4px;
		text-align: right;

		&::before, &::after {
			transform-origin: left;
		}

		@include mq($from: desktop) {
			right: 24px;
		}
	}
}


// Logo

@keyframes showLetter {
	from {
	   opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes hideLetter {
	from {
	   opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.c-global-head {
	&__logo {
		width: 100px;
		display: inline-block;
		margin-left: $spacer-s;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		margin: 0;
		top: 12px;
		@include transition(none);

		@include mq($from: $header-full-display) {
			width: 235px;
		}

		* {
			fill: $white !important;
		}

		// Logo animation

		[id*="-b"] {
			opacity: 0;
		}

		// Alemeida text

		@for $i from 1 through 14 { // spin up incremental animation delays
			$delay: 30*$i+1000;
			$delay2: 30*$i+2500;

			[id*="-b"]:nth-of-type(#{$i}) {
				animation: showLetter 0.1s ease-out $delay+ms forwards, hideLetter 0.1s ease-out $delay2+ms forwards;
			}
		}

		@for $i from 1 through 14 { // spin up incremental animation delays
			$delay: 40*$i+1000; // Offset slightly for seamless stitch
			$delay2: 30*$i+2500;

			[id*="-a"]:nth-of-type(#{$i}) {
				animation: hideLetter 0.1s ease-out $delay+ms forwards, showLetter 0.1s ease-out $delay2+ms forwards;
			}
		}

		// Theatre text

		@for $i from 15 through 28 { // spin up incremental animation delays
			$delay: 30*($i - 13)+1000;
			$delay2: 30*($i - 13)+2500;

			[id*="-b"]:nth-of-type(#{$i}) {
				animation: showLetter 0.1s ease-out $delay+ms forwards, hideLetter 0.1s ease-out $delay2+ms forwards;
			}
		}

		@for $i from 15 through 28 { // spin up incremental animation delays
			$delay: 40*($i - 13)+1000; // Offset slightly for seamless stitch
			$delay2: 30*($i - 13)+2500;

			[id*="-a"]:nth-of-type(#{$i}) {
				animation: hideLetter 0.1s ease-out $delay+ms forwards, showLetter 0.1s ease-out $delay2+ms forwards;
			}
		}
	}
}

// Nav menu

body {
	position: static;
	width: 100%;

	&.state--nav-open {
		position: fixed;
	}
}

#c-menu {
	z-index: 500;
	top: $header-height-mobile;
	right: 0;
	bottom: 0;
	left: 0;
	position: fixed;
	transform: translateY(-100%);
	transition: transform $transiton-timing-slow;
	transition-delay: 0s;

	@include mq($from: $header-full-display) {
		top: $header-height-desktop;
	}

	.state--nav-open & {
		transition-delay: $transiton-timing-fast;
		transform: translateY(0);
	}
}


// Menu trigger

button.c-menu-toggle {
	padding: 0;
	display: inline-block;
	cursor: pointer;
	border: 0;
	color: inherit;
	@include font-semi-bold;
	text-align: left;
	background: transparent;
	display: inline-block;
	width: 100px;
	-webkit-appearance: none;
	position: relative;
	@include font-size-1;
	margin-top: 5px;
	@include fonts;

	@include mq($from: $header-full-display) {
		margin-right: $spacer-m;
		margin-top: 0;
	}

	&:hover {
		background: transparent;
		color: $white;
	}

	&:focus-visible { @include focus-outline; }
}

.c-menu-toggle__label {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	z-index: 20;
	@include font-bold;

	@include mq($until: ($header-full-display - 1)) {
		@include inuit-hidden-visually();
	}

	&--close {
		display: none;
	}
}

.c-global-head-nav {
	@include font-bold;
	@include font-size-1;
	display: inline-block;

	&__item {
		text-decoration: none;
		margin-right: $spacer-m;
		color: $white;

		&:hover {
			opacity: 0.7;
		}

		&:last-of-type {
			margin: 0;
		}

		&--mobile {
			display: inline-block;
		}

	}

	.fas {
		font-size: 0.9em;
	}

	.c-global-head-nav__item-text {
		display: none;
	}

	.c-global-head-nav__item--whats-on {
		@include mq($from: mobile) {
			.c-global-head-nav__item-text {
				
			}
		}
	}

	//
	// Until header full width
	@include mq($until: ($header-full-display - 1)) {
		.c-global-head-nav__item-text {
			@include inuit-hidden-visually();
		}
	}
	//
	// From header full width
	@include mq($from: $header-full-display) {
		i {
			margin-right: $spacer-s;
		}
		.c-global-head-nav__item-text {
			display: inline-block;
		}
	}
}
