.tn-cart-buttons__item-container {
	.tn-cart-buttons__secondary-action {
		@include c-cta;
	}
}

.tn-performance-title.tn-cart-line-item-name {
	@include heading-regular;
	@include font-size-2;
}

.tn-selected-shipping-method {
	@include font-size-0;
	@include font-reg;
	margin-bottom: 1em;
}

a.tn-edit-billing-link, a.tn-edit-delivery-link {
	@include c-cta;
}

// Targets 'agree to the Terms & Conditions' checkbox
#Checkout_FormKey + .form-group {
	background-color: $gray-lighter;
	border: 1px solid darken($gray-lighter, 10%);
	padding: 6px 12px;
	margin-top: 1em;
	.checkbox {
		display: flex;
		flex-direction: column;
		input[type=checkbox] {
			position: relative;
			margin-left: 0;
			margin-right: $spacer-s;
		}
		label {
			display: block;
			padding-left: 0;
			@include font-size-1;
		}
		&input:checked label {
			@include font-bold;
		}
		.tn-required-field {
			color: $error;
		}
		.form-control-feedback {
			display: none;
		}
	}
}

// Donation form
.tn-donation-ask-component {
	background-color: transparentize($color-red, 0.85);
	padding: $spacer-s;
	margin-bottom: $spacer-s;
    @include mq($from: tablet) {
		padding: $spacer-m;
		margin-bottom: $spacer-m;
		// Override native width columns
		.col-md-4:has(#tn-add-donation-form) {
			width: 100%;
			.tn-donation-ask-component__input-field-wrapper {
				max-width: 400px;
			}
		}
    }
}