.tn-subnav-component {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    width: 100%;
    padding: 7px 0;
    font-size: 90%;
    a, a span {
        font-weight: 400 !important;
        font-size: 100% !important;
    }
    .tn-text {
        font-size: initial;
    }

    // we already have a cart link in the header
    .tn-cart-link .tn-link {
        display: none;
    }

    // login 

    .tn-login-link {
        .tn-link {
            display: flex;
        }
        .tn-link .tn-text {
            display: block !important;
        }
        &:not(.tn-logged-in) .tn-icon {
            &:before {
                font-family: 'Font Awesome 5 Pro' !important;
                font-weight: 300;
                content: '\f090' !important;
                color: $brand-primary;
            }
        }
        &.tn-logged-in .tn-icon {
            display: none !important;
        }
        @include mq($until: desktop){
            &.tn-logged-in .tn-account-info-link {
                display: none !important;
            }
            .tn-logout-link:before {
                display: none !important;
            }
        }
    }

    // logout

    .tn-login-link.tn-logged-in .tn-logout-link {
        display: inline-block !important;
    }
    .tn-logout-link .tn-text {
        color: $brand-primary;
        &:before {
            font-family: 'Font Awesome 5 Pro' !important;
            font-weight: 300;
            content: '\f08b' !important;
            margin-right: 0.2em;
            color: $brand-primary;
        }
    }
    
    .tn-cart-link.tn-active .tn-timer {
		background-color: $gray-lighter;
        border: 1px solid darken($gray-lighter, 10%);
		padding: 3px 6px;
		font-size: 90%;
		display: inline-block;
		text-align: center;
        
		@include mq($from: desktop){
			min-width: 180px;
		}
	}
    .tn-promo-box {
        margin-right: 0;
        #tn-apply-promo {
            display: flex;
            flex-direction: row;
            align-items: center;
            @include font-semi-bold;
        }
        #tn-subnav-promo-code {
            transition: box-shadow .5s,border-color .25s ease-in-out;
            color: $text-color;
            background-color: $white;
            border: 1px solid #aaa;
            padding-left: 0.5em;
            padding-right: 0.5em;
            height: 34px;
            border-radius: 4px 0 0 4px;
            min-width: 7.5em;
        }
        #tn-subnav-promo-button {
            border-radius: 0 4px 4px 0;
            text-decoration: none;
            font-size: 0;
            padding-right: 2.5em;
            height: 34px;
            width: 36px;
            background-color: $brand-primary;
            position: relative;
            &:before {
                content: "\E080";
                position: absolute;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
                font-size: 16px;
                font-family: Glyphicons Halflings;
                font-style: normal;
                color: $black;
            }
        }
    }
}
