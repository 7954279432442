//
// Buttons
// --------------------------------------------------
// Base styles
// --------------------------------------------------
.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: $btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  @include font-bold;
  @include button-size($button-padding-vertical, $button-padding-horizontal, $font-size-base, $line-height-base, $border-radius-base);
  @include user-select(none);
  @include mq($from: desktop) {
    font-size: 18px;
  }
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }
  &:hover,
  &:focus,
  &.focus {
    background-color: saturate($btn-default-color, 10%);
    box-shadow: rgba($black, 0.1) 0 4px 12px;
    transform: translateY(-1px);
  }
  &:active,
  &.active {
    background-color: mix($btn-default-color, $white, 90%);
    box-shadow: rgba($black, 0.1) 0 2px 4px;
    transform: translateY(0);
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    pointer-events: none; // Future-proof disabling of clicks
    @include opacity(.65);
    @include box-shadow(none);
  }
}

// Alternate buttons
// --------------------------------------------------
.btn-default {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}

.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

// Success appears as green
.btn-success {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

// Info appears as blue-green
.btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

// Warning appears as orange
.btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

// Danger and error appear as red
.btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

// Link buttons
// -------------------------
// Make a button look and behave like a link
.btn-link {
  color: $link-color;
  font-weight: normal;
  border-radius: 0;
  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    @include box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

// Button Sizes
// --------------------------------------------------
.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $border-radius-large);
}

.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $border-radius-small);
}

.btn-xs {
  @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $border-radius-small);
}

// Block button
// --------------------------------------------------
.btn-block {
  display: inline-block;
  width: auto;
  max-width: 400px;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: auto;
  }
}

/*  Call to action
    ========================================================================== */

@mixin c-cta {
    display: inline-block;
    cursor: pointer;
    position: relative;
    @include font-bold;
    text-decoration: none;
    padding: 0.133rem 0;
    z-index: 30;
    transition: $global-transition;

    &::before, &::after {
        content: "";
        background: currentColor;
        height: 1px;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 100%;
        width: 100%;
        transform-origin: 0% 0%;
        transform: scaleX(100%);

    }

    &::before {
        height: 2px;
        left: 0;
        transition: transform $transiton-timing $transiton-easing-function;
    }

    &::after {
        top: calc(100% + 4px);
        transition: transform $transiton-timing $transiton-easing-function $transiton-timing-fast;
    }

    &:hover, &:focus {
        transform: translateY(-1px);
        &::before, &::after {
            transform: scaleX(0);
            content: "";
            transform-origin: 100% 50%;
        }
    }

    i {
        margin-left: $spacer-s;
        font-size: 90%;

        &.fa-angle-double-left {
            margin-left: 0;
            margin-right: $spacer-s;
        }
        &.fa-angle-double-right {
            margin-left: $spacer-s;
            margin-right: 0;
        }
    }

    // Sets a spacer on call to actions to display them as a block item
    p + & {
        &::before {
            display: block;
            margin-top: 0.4rem;
            content: '';

            @include mq($from: tablet) {
                margin-top: 0.8rem;
            }
        }
    }

    // Reduces margin with two CTAs stacked on top of each other
    & + & {
        &::before {
            margin-top: 0.2rem;

            @include mq($from: tablet) {
                margin-top: 0.4rem;
            }
        }
    }
}

.c-cta {
    @include c-cta;
}

