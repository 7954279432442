
$font-path: "https://almeida-tnew.s3.eu-west-1.amazonaws.com/qa/fonts";


@font-face {
    font-family: 'regular';
    src: url('#{$font-path}/regular-medium-webfont.woff2') format('woff2'),
         url('#{$font-path}/regular-medium-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'regular';
    src: url('#{$font-path}/regular-regular-webfont.woff2') format('woff2'),
         url('#{$font-path}/regular-regular-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'regular';
    src: url('#{$font-path}/regular-semibold-webfont.woff2') format('woff2'),
         url('#{$font-path}/regular-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'regular';
    src: url('#{$font-path}/regular-bold-webfont.woff2') format('woff2'),
         url('#{$font-path}/regular-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'regular';
    src: url('#{$font-path}/regular-black-webfont.woff2') format('woff2'),
         url('#{$font-path}/regular-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'regular';
    src: url('#{$font-path}/regular-regularitalic-webfont.woff2') format('woff2'),
         url('#{$font-path}/regular-regularitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: auto;

}

@font-face {
    font-family: 'regular';
    src: url('#{$font-path}/regular-mediumitalic-webfont.woff2') format('woff2'),
         url('#{$font-path}/regular-mediumitalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: auto;
}

/* ==========================================================================
   #Fonts
   ========================================================================== */


// Font Face
@mixin fonts {
    font-family: 'regular', sans-serif;
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased !important;
    text-rendering: optimizeLegibility !important;
}

@mixin font-thin {
    font-weight: 100;
}
@mixin font-light {
    font-weight: 300;
}
@mixin font-reg {
    font-weight: 400;
}
@mixin font-med {
    font-weight: 500;
}
@mixin font-semi-bold {
    font-weight: 600;
}
@mixin font-bold {
    font-weight: 700;
}
@mixin font-heavy {
    font-weight: 800;
}
@mixin font-super-heavy {
    font-weight: 800;
}
@mixin font-italic {
    font-style: italic;
}


@mixin ff-heading {
    font-family: 'regular';
    @include font-super-heavy;
}

@mixin ff-body {
    font-family: 'regular';
}


/* Font awesome fonts
   ========================================================================== */

@mixin fa-reg {
    font-family: 'Font Awesome 5 Pro';
    @include font-reg;
}

@mixin fa-light {
    font-family: 'Font Awesome 5 Pro';
    @include font-light;
}

@mixin fa-solid {
    font-family: 'Font Awesome 5 Pro';
    @include font-super-heavy;
}


@mixin fa-brands {
    font-family: 'Font Awesome 5 Brands';
}

@mixin fa-dutone {
    font-family: 'Font Awesome 5 Dutone';
}
